<template>
  <b-card-code no-body title="Auction Transaction History">

    <div class="dt_adv_search ml-1 mb-1 mr-1">
      <div class="row">

        <div class="col-md-2 mt-1">
          <flat-pickr
            v-model="filter.date"
            class="form-control form-control-sm"
            placeholder="Select date..."
            :config="{dateFormat: 'Y-m-d'}"
          />
        </div>

        <div class="col-md-2 mt-1">
          <select class="form-control form-control-sm" v-model="filter.status">
            <option value="">-- All Status --</option>
            <option value="pending">Pending</option>
            <option value="success">Success</option>
            <option value="failed">Failed</option>
            <option value="expired">Expired</option>
          </select>
        </div>

        <div class="col-md-2 mt-1">
          <select class="form-control form-control-sm" v-model="filter.type">
            <option value="">-- All Type --</option>
            <option value="bid">Bid</option>
            <option value="ask">Ask</option>
          </select>
        </div>

        <div class="col-md-2 mt-1">
            <v-select
              v-model="filter.user_id"
              :options="users"
              :reduce="option => option.id"
              @search="onSearchUser"
            >
              <template slot="no-options">
                Type user name, code, or email..
              </template>
            </v-select>
        </div>

        <div class="col-md-2 mt-1">
            <select class="form-control form-control-sm" v-model="filter.commodity_slug">
              <option selected value="">-- All Asset --</option>
              <option v-for="commodity in commodities" v-bind:key="commodity.slug" v-bind:value="commodity.slug">{{ commodity.name }}</option>
            </select>
        </div>

          <div class="col-md-2 mt-1">
              <select class="form-control form-control-sm" v-model="filter.warehouse_slug">
                <option selected value="">-- All Depository --</option>
                <option v-for="warehouse in warehouses" v-bind:key="warehouse.slug" v-bind:value="warehouse.slug">{{ warehouse.name }}</option>
              </select>
          </div>

      </div>
    </div>


    <div class="table-responsive-sm">
      <table class="table b-table">
        <thead>
          <tr>
            <th class="pl-1">
              ID
            </th>
            <th class="pl-0">
              Type
            </th>
            <th class="pl-0">
              User
            </th>
            <th class="pl-0">
              Auction
            </th>
            <th class="pl-0" style="cursor: pointer;" @click="sortBy('created_at')">
              Time
              <span v-if="filter.sort_by == 'created_at'">
                <svg v-if="filter.sort_type == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                </svg>
                <svg v-if="filter.sort_type == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                </svg>
              </span>
            </th>
            <th class="pl-0" style="cursor: pointer; min-width: 40px;" @click="sortBy('price')">
              Order Price
              <span v-if="filter.sort_by == 'price'">
                <svg v-if="filter.sort_type == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                </svg>
                <svg v-if="filter.sort_type == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                </svg>
              </span>
            </th>
            <th class="pl-0" style="cursor: pointer; min-width: 40px;" @click="sortBy('fixed_price')">
              Match Price
              <span v-if="filter.sort_by == 'fixed_price'">
                <svg v-if="filter.sort_type == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                </svg>
                <svg v-if="filter.sort_type == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                </svg>
              </span>
            </th>
            <th class="pl-0" style="cursor: pointer;" @click="sortBy('lot')">
              Lot
              <span v-if="filter.sort_by == 'lot'">
                <svg v-if="filter.sort_type == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                </svg>
                <svg v-if="filter.sort_type == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                </svg>
              </span>
            </th>
            <th class="pl-0">
              Status
            </th>
            <th class="pl-0"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="9" class="text-center">
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr v-else v-for="item in result.data" v-bind:key="item.slug">
            <td class="pl-1" style="text-transform: capitalize;">{{ item.id }}</td>
            <td class="pl-0" style="text-transform: capitalize;">{{ item.type }}</td>
            <td class="pl-0" v-if="item.user != null">
                <router-link :to="{ name: 'users.show', params: { id: item.user.id } }">
                    {{ item.user.name }}<br><small>{{ item.user.email }}</small>
                </router-link>
              </td><td v-else>-</td>
            <td class="pl-0">{{ item.auction.commodity.code }}<br><small>{{ item.auction.warehouse.name }}</small></td>
            <td class="pl-0"><small>{{ item.time }}</small></td>
            <td class="pl-0">{{ item.price }}</td>
            <td class="pl-0">{{ item.lot_complete > 0 ? item.fixed_price : '-' }}</td>
            <td class="pl-0">{{ item.lot }} lot</td>
            <td class="pl-0" style="text-transform: capitalize;">
              {{ item.status }}<br>
              <span v-if="item.lot_complete > 0">
                {{ item.lot_complete >= item.lot ? 'Fill' : 'PartFill'}} {{ item.lot_complete + '/' + item.lot }}
              </span>
            </td>
            <td class="pl-0">
                <b-dropdown
                    variant="flat-success"
                    size="sm"
                    v-if="checkPermission('show auctions') ||checkPermission('show auction transaction detail') "
                >
                    <template #button-content>
                    <eye-icon size="1x" class="custom-class"></eye-icon> Detail
                    </template>
                    <b-dropdown-item v-if="checkPermission('show auction transaction detail')" @click="goToDetailTransaction(item.uuid)">Detail Transaction</b-dropdown-item>
                    <b-dropdown-item v-if="checkPermission('show auctions')" @click="goToDetailAuction(item.auction.uuid)">Detail Auction</b-dropdown-item>
                </b-dropdown>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td colspan="9" class="text-center">Data is empty.</td>
          </tr>
        </tbody>
      </table>

      <div class="m-1" v-if="result.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import { EyeIcon } from 'vue-feather-icons'
import { checkPermission } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  title () {
    return `Auction Transaction History`
  },
  setup() {
    return {
      checkPermission,
    }
  },
  components: {
    BCard,
    BButton,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardCode,
    EyeIcon,
    flatPickr,
    BSpinner,
    vSelect
  },
  watch: {
    filter: {
      handler: _.debounce(function() {                                                                                                                                                              
        this.getData();                                                                                                                                                                        
      }, 0),
      deep: true
    },
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        date: null,
        status: this.$route.query.status == null ? '' : this.$route.query.status,
        type: '',
        user_id: '',
        commodity_slug: '',
        warehouse_slug: '',
        sort_by: null,
        sort_type: null,
      },
      isLoading: false,
      warehouses: [],
      commodities: [],
      users: [],
    }
  },
  mounted() {
    var context = this
    this.$echo.channel('DashboardAdminUpdate').listen('.DashboardAdminUpdate', (payload) => {
      if (payload.type == 'auction-transaction') {
        context.getData(context.currentPage || 1);  
      }
    });
  },
  created() {
    this.getData();
    this.loadFormData();
    this.executeSearch('')
  },
  methods: {
    loadFormData() {
      
      this.$http.get('/public/commodities/warehouses')
      .then(response => {
        this.warehouses = response.data.data
      })
      this.$http.get('/public/commodities/')
      .then(response => {
        var context = this;
        this.commodities = response.data.data
      })

    },
    sortBy(by) {
      if (this.filter.sort_by != by) {
        this.filter.sort_type = null
      }
      this.filter.sort_by = by
      
      if (this.filter.sort_type == null) {
        this.filter.sort_type = 'asc';
      } else if (this.filter.sort_type == 'asc') {
        this.filter.sort_type = 'desc';
      } else {
        this.filter.sort_type = null;
      }

      this.getData()
    },
    getData(page = 1) {

      // this.isLoading = true;
      this.currentPage = page;
      var queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/auction-transactions', {
        params: queryParams
      })
      .then(response => {
        this.result = response.data.data
        // this.isLoading = false;
      })

    },
    goToDetailAuction(uuid) {
        this.$router.push({ name: 'auctions.show', params: { uuid: uuid } })
    },
    goToDetailTransaction(uuid) {
        this.$router.push({ name: 'auctions.transactions.show', params: { uuid: uuid } })
    },
    executeSearch(query){
      var vm = this
      this.$http.get('/admin/users?user_type=normal&per_page=200&sort_by_register=desc&keyword=' + escape(query))
      .then(response => {
        vm.users = [];
        response.data.data.data.forEach(function(user){
          vm.users.push({
            'id': user.id,
            'label': user.name + ' (' + user.name + ')'
          })
        });

      })
    },
    onSearchUser(search, loading) {
      if(search.length) {
        loading(true);
        this.searchUser(loading, search, this);
      }
    },
    searchUser: _.debounce((loading, search, vm) => {
      vm.executeSearch(escape(search))
      loading(false);
    }, 300),
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>